<template>
  <!-- Main jumbotron for a primary marketing message or call to action -->

  <section id="nosotros-ayudamos">
    <div class="container jumbotron pt-5">
      <div class="row justify-content-center" style="text-align: justify;">
        <div class="col-10">
          <h2 class="section-title">
            Aviso de Privacidad Integral - Ibancar Latam S.A. de C.V. 
          </h2>
          <p>
            Tu privacidad y confianza son de suma importancia para Ibancar Latam S.A. de C.V. (en adelante, Ibancar Latam). Por ello, queremos asegurarnos de que comprendas cómo protegemos y salvaguardamos la integridad y privacidad de tus datos personales, conforme a la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (en adelante, la "Ley") y su Reglamento.
            <br />
            Ibancar Latam S.A. de C.V. necesita recopilar ciertos datos personales para llevar a cabo sus actividades comerciales y mercantiles. Asimismo, estamos comprometidos a cumplir con todas las medidas legales y de seguridad necesarias para proteger los datos personales que recolectemos para los fines que se describen en este Aviso de Privacidad.
            <br />
            Todo esto se hace para que tengas pleno control, conocimiento y decisión sobre tus datos personales y su tratamiento. Te recomendamos que leas detenidamente la siguiente información.
          </p>
          <h5>1. Datos del Responsable</h5>
          <p>
            Ibancar Latam S.A. de C.V. es una sociedad constituida conforme a las leyes de los Estados Unidos Mexicanos, con domicilio en Av. Paseo de la Reforma 180, piso 11, interior 1101, Juárez, Cuauhtémoc, 06600 Ciudad de México, CDMX.<br />
          </p>
          <h5>2. Datos Personales que se tratarán</h5>
          <p>
            Para proporcionarte nuestros servicios de manera adecuada y cumplir con las finalidades establecidas en este Aviso de Privacidad, Ibancar Latam S.A. de C.V. recopilará y tratará los siguientes datos personales:
            a) Datos personales de identificación, incluyendo tu imagen personal (fotografía). <br />
            b) Datos personales de contacto. <br />
            c) Datos de autenticación. <br />
            d) Datos Biométricos. <br/>
            e) Datos patrimoniales y/o financieros. <br/>
            f) Datos Laborales. <br/>
            g) Datos de ubicación. <br/>
            h) Coordenadas geográficas de latitud y longitud de los dispositivos ópticos y/o electrónicos utilizados para solicitar y contratar los productos y servicios de Ibancar Latam S.A. de C.V. ("geolocalización").<br/>
          </p>
          <p>
            Los datos personales que nos proporciones serán tratados únicamente para los fines establecidos en este Aviso de Privacidad, con todas las medidas de seguridad y confidencialidad aplicables.
          </p>

          <h5>3. Datos Personales Sensibles que se tratarán</h5>
          <p>
            En ciertos casos, Ibancar Latam S.A. de C.V. podrá recopilar datos personales sensibles, como datos de salud relacionados con la detección y atención de emergencias sanitarias y/o contingencias de salud. Estos datos serán tratados con las medidas de seguridad y confidencialidad correspondientes, de acuerdo con lo establecido en este Aviso de Privacidad.<br />
          </p>
          <h5>4. Finalidades Primarias del Tratamiento de Datos</h5>
          <p>
            En adelante, Ibancar Latam S.A. de C.V. tratará tus datos personales única y exclusivamente de acuerdo con los términos del presente Aviso de Privacidad.<br/>
            Los datos personales que nos has proporcionado serán utilizados para las siguientes finalidades primarias, que son necesarias y dan origen a la relación contractual entre Ibancar Latam, en su carácter de Responsable, y tú como Titular de los datos personales:<br/>
            a) Realizar actividades relacionadas con la operación de los servicios y/o créditos que contrates con Ibancar Latam, incluyendo, entre otros: procesamiento de solicitudes, administración, originación, activación y desembolso; actividades de cobranza, comunicaciones por correo electrónico, llamadas telefónicas, mensajería instantánea y otros medios electrónicos; aclaración, investigación y facturación de cargos relacionados con los créditos y/o servicios; monitoreo periódico de tu comportamiento crediticio; visitas domiciliarias para validaciones de datos; entrega de información para la gestión de solicitudes; creación de perfiles de cliente y comportamiento de pagos. En el proceso de solicitud y para cumplir con la relación jurídica originada por dichas solicitudes, Ibancar Latam, junto con los proveedores que presten servicios para este fin, tratará tus datos personales para llevar a cabo análisis de riesgo y viabilidad crediticia, monitoreo y análisis estadísticos, así como la generación de modelos de información y perfiles de comportamiento actual y predictivo. <br />
            b) Realizar la originación, operación, gestión, procesamiento y administración de los créditos y/o servicios contratados con Ibancar Latam. <br />
            c) Generar datos de autenticación para crear un perfil de acceso a los sistemas electrónicos, prestando así una atención personalizada y acreditando tu identidad cuando utilices equipos o sistemas electrónicos y/o automatizados; para mantener el control de acceso en nuestras instalaciones y garantizar la seguridad de clientes, empleados, visitantes y proveedores mediante dispositivos y medios electrónicos u otras tecnologías. <br />
            d) Evaluar la calidad de los créditos y/o servicios contratados con Ibancar Latam mediante encuestas, ya sea por medios escritos o electrónicos. <br/>
            e) Acreditar tu identidad y validar la información y/o datos proporcionados de acuerdo con los procedimientos internos de Ibancar Latam, incluyendo la solicitud de información patrimonial y/o financiera, así como datos crediticios, para verificar dicha información o información demográfica como tu domicilio, con el prestador de servicios o la sociedad de información crediticia de nuestra preferencia.<br/>
            f) Crear e implementar procesos analíticos y estadísticos necesarios asociados a los créditos y/o servicios de Ibancar Latam.<br/>
            g) Prestar servicios relacionados con asistencia y mantenimiento automotriz de acuerdo a los términos y condiciones establecidos en el contrato que hayas firmado con Ibancar Latam S.A. De C.V.<br/>
            h) Enviar avisos, notificaciones, comunicaciones, encuestas de servicio e información adicional relacionada con los créditos y/o servicios, así como monitorear a través de llamadas telefónicas, correos electrónicos, mensajes de texto y/o cualquier otro medio de contacto, respecto de cualquier actividad relacionada con los créditos y/o servicios que ofrece Ibancar Latam.<br/>
            i) Contactarte en relación con temas relacionados con el crédito y/o servicios de Ibancar Latam, así como aquellos productos y/o servicios que hayas contratado donde Ibancar Latam S.A. de C.V. actúe como intermediaria en su venta.
            j) Integrar expedientes y bases de datos, así como dar tratamiento a los mismos, directamente por Ibancar Latam o a través de terceros o proveedores necesarios para la prestación de los servicios propios de su objeto y reportar a las autoridades correspondientes según lo establecido por las disposiciones legales vigentes.<br/>
            k) Integrar en tu expediente documentación que pueda contener tu fotografía como medida preventiva contra fraudes y/o robos de identidad.<br/>
            l) Determinar, administrar y utilizar la información relacionada con la "geolocalización" de los dispositivos ópticos y/o electrónicos utilizados en la solicitud y contratación de los créditos y/o servicios de Ibancar Latam.<br/>
            m) Realizar la verificación y/o validación de los datos personales proporcionados, incluida su veracidad, directamente con autoridades gubernamentales y/o contra bases de datos y, en caso de discrepancia, llevar a cabo las rectificaciones o correcciones necesarias para mantener actualizada tu información.<br/>
            n) Llevar a cabo procesos de verificación, compulsa y consulta de bases de datos de terceros, públicas y/o privadas, para mantenernos en contacto contigo en relación con los créditos y/o servicios de Ibancar Latam.<br/>
            o) Grabar las llamadas que mantengas con Ibancar Latam S.A. de C.V. para cualquier finalidad relacionada con los créditos y/o servicios y/o evaluar la calidad del servicio.<br/>
            p) Realizar, atender y dar seguimiento a consultas, investigaciones y/o revisiones de quejas, aclaraciones y/o reclamaciones relacionadas con los créditos y/o servicios de Ibancar Latam, o cualquier conducta que pudiera contravenir la normatividad aplicable y/o nuestro contrato de servicios.<br/>
            q) Contactarte proactivamente con el fin de atender y dar seguimiento a investigaciones y/o revisiones de quejas, aclaraciones y/o reclamaciones relacionadas con los créditos y/o servicios de Ibancar Latam.<br/>
            r) Utilizar los datos personales en casos de fusión, escisión o adquisición de Ibancar Latam, incluyendo sus filiales y/o subsidiarias, así como para la cesión o descuento de cartera, endoso o cualquier otra forma de negociación de la cartera aún antes de su vencimiento.<br/>
            s) Realizar auditorías internas o externas y cumplir con las obligaciones de colaboración y/o requerimientos de información formulados por las autoridades gubernamentales competentes, cuando sea necesario para salvaguardar el interés público, la procuración o administración de justicia y/o en términos de la legislación vigente aplicable. <br/>
            t) Cumplir con las obligaciones asociadas a los créditos y/o servicios que tengas contratados con Ibancar Latam y realizar cualquier actividad complementaria o auxiliar asociada a los créditos y/o servicios de Ibancar Latam.<br/>
            u) Cumplir con las obligaciones de colaboración con autoridades competentes según la legislación aplicable y cumplir con las leyes, reglamentos y disposiciones legales aplicables.<br/>

          
          </p>
          <h5>5. Finalidades Secundarias del Tratamiento de Datos</h5>
          <p>
              Adicionalmente, en caso de que no manifiestes lo contrario, Ibancar Latam S.A. de C.V. tratará tus datos personales para las siguientes finalidades secundarias:<br/>
              a) Realizar promociones, ofertas y publicidad respecto de los productos y servicios de Ibancar Latam, incluyendo servicios y/o créditos, o cualquier otro producto emitido con la marca Ibancar a través de medios electrónicos, telefónicos y/o impresos.<br/>
              b) Realizar comunicaciones relacionadas con invitaciones y/o eventos organizados por Ibancar Latam.<br/>
              c) Ofrecer, promocionar, vender y/o comercializar productos y/o servicios operados por compañías aseguradoras por medio de Ibancar Latam, directamente por las compañías aseguradoras o por cualquier otro intermediario con quien Ibancar Latam S.A. de C.V. tenga acuerdos comerciales debidamente formalizados.<br/>
              Si no deseas que tus datos personales se utilicen para las mencionadas finalidades secundarias, comunícate a los teléfonos de nuestro Departamento de Datos Personales: 
              55 5566 1583, 
              de lunes a viernes de 9:00 A.M. a 6:00 P.M.
          </p>
          <h5>6. Transferencia de Datos Personales</h5>
          <p>
              Con la finalidad de cumplir con las obligaciones derivadas de tu relación jurídica con Ibancar Latam, así como con las finalidades establecidas en este Aviso de Privacidad, Ibancar Latam S.A. de C.V. podrá transferir tus datos personales a terceros, ya sean mexicanos o extranjeros, sin necesidad de obtener tu consentimiento, cuando la transferencia sea a:<br/><br/>
              a) Compañías aseguradoras en caso de que adquieras productos y/o servicios con dichas aseguradoras a través de la intervención de Ibancar Latam S.A. de C.V. y/o alguna empresa subsidiaria o filial facultada por ley para tal propósito.<br/>
              b) Terceros con los que Ibancar Latam S.A. de C.V. formalice o pretenda formalizar acuerdos relacionados con la cesión, descuento de cartera, endoso u otra forma de transmisión de los derechos y/u obligaciones derivados de la relación jurídica contigo, incluyendo, sin limitar, la información de los Tarjetahabientes Suplementarios.<br/>
              c) Otros socios comerciales y/o prestadores de servicios para llevar a cabo actividades relacionadas directa o indirectamente con los créditos y/o servicios, así como el cumplimiento de sus obligaciones jurídicas, regulatorias y/o cualquier otra aplicable.<br/>
              d) Aquellas sociedades que formen parte del grupo económico de Ibancar, incluyendo la compañía controladora, así como empresas subsidiarias y filiales, para llevar a cabo cualquier actividad relacionada con los créditos y/o servicios contratados contigo. Esta transferencia no requiere de tu consentimiento debido a que las empresas del grupo operan bajo los mismos procesos, políticas y estándares de privacidad y protección de datos.<br/>
              e) Autoridades competentes y organismos gubernamentales locales o federales, incluyendo, sin limitar:<br/>
              &nbsp;&nbsp;&nbsp;1) Hacendarias: para validar y verificar tu información fiscal, Registro Federal de Contribuyentes (RFC), datos de identificación y contacto.<br/>
              &nbsp;&nbsp;&nbsp;2) Protección al consumidor: en relación con los créditos y/o servicios que tienes contratados con Ibancar Latam.<br/>
              &nbsp;&nbsp;&nbsp;3) Cualquier otra autoridad, para cualquier finalidad prevista en la legislación vigente y aplicable.<br/>
            Ibancar Latam S.A. de C.V. se compromete a garantizar el cumplimiento de los principios legales de protección en relación con la transferencia de tus datos personales y asegurarse de que tanto nosotros como nuestros socios comerciales y/o prestadores de servicios respetemos este Aviso de Privacidad en todo momento.
          </p>
          <h5>7. Limitación de Uso o Divulgación de los Datos Personales</h5>
          <p>
            Si deseas limitar el uso o divulgación de tus datos para dejar de recibir promociones, ofertas y publicidad relacionadas con los créditos y/o servicios de Ibancar Latam, así como de los productos y/o servicios en los que actuamos como intermediarios en su venta, por favor comunícate a los teléfonos de nuestro Departamento de Datos Personales: 55 5566 1583, de lunes a viernes de 9:00 A.M. a 6:00 P.M.
            <br/>
            En caso de que tu solicitud resulte procedente, Ibancar Latam S.A. de C.V. llevará a cabo el proceso de exclusión correspondiente para que dejes de recibir nuestras promociones.
            <br />
          </p>
          <h5>8. Medios para Ejercer los Derechos ARCO y/o revocar el consentimiento</h5>
          <p>De acuerdo con lo establecido en la Ley y su Reglamento, podrás ejercer tus derechos de acceso, rectificación, cancelación y/u oposición (Derechos ARCO) respecto de tus datos personales, así como revocar cualquier consentimiento que nos hayas otorgado previamente relacionado con este Aviso de Privacidad. Para ello, puedes llamar directamente a los teléfonos de nuestro Departamento de Datos Personales: 55 5566 1583, de lunes a viernes de 9:00 A.M. a 6:00 P.M., acreditando tu identidad o la de tu representante legal y describiendo claramente los datos personales sobre los que ejerces tus Derechos ARCO.<br />
            Es importante mencionar que durante la llamada para ejercer tus Derechos ARCO, deberás proporcionar una dirección de correo electrónico y/o número telefónico para notificar la respuesta a tu solicitud o contactarte en caso de requerir información adicional relacionada con tu petición.<br />
            Además, deberás enviar copia de tu identificación oficial, y en el caso de tu representante legal, éste deberá enviar copia de tu identificación oficial, así como documentación que acredite su representación, para verificar su identidad.<br />
            La determinación adoptada en respuesta a tu solicitud será notificada en un plazo máximo de 20 (veinte) días hábiles a partir de que se cumplan todos los requisitos establecidos en la solicitud de acceso, rectificación, cancelación y/u oposición. En caso de que proceda, la solicitud será efectiva dentro de los 15 (quince) días hábiles siguientes a la fecha de la notificación.<br />
          </p>
          <h5>9. Departamento de Datos Personales
          </h5>
          <p>
            Ibancar Latam S.A. de C.V. cuenta con un Departamento de Datos Personales al que podrás contactar para cualquier asunto relacionado con tus datos personales o el presente Aviso de Privacidad, al s siguiente número telefónico:<br/> 
            55 5566 1583 Horario: lunes a viernes de 9:00 A.M. a 6:00 P.M.<br/>
          </p>
          <h5>10. Uso de Cookies
          </h5>
          <p>
            Los diferentes Sitios de Internet y servicios en línea de Ibancar Latam S.A. de C.V. utilizan Cookies, Web Beacons y/o Tecnología Similar para recopilar automáticamente datos personales y monitorear tu comportamiento como usuario de nuestros servicios. Estas tecnologías se utilizan para brindarte la mejor experiencia durante tu navegación, mejorar la seguridad de tu cuenta, definir tus preferencias de contenido y personalizar la información con base en tus intereses o dar seguimiento a tus compras en línea.<br/>
            El uso de estas tecnologías es necesario para operar, técnicamente y por seguridad, nuestro Sitio de Internet.<br/>
            Si lo deseas, puedes deshabilitar o ajustar el uso de estas tecnologías siguiendo los procedimientos de tu navegador de internet y/o del dispositivo móvil mediante el cual accedes a nuestros servicios.<br/>
            Al ingresar y continuar usando nuestro Sitio de Internet, aceptas que Ibancar Latam S.A. de C.V. recabe y trate tus datos personales.<br/>
            Para obtener más información, consulta los términos y condiciones de nuestro Sitio de Internet y los términos y condiciones del uso de Cookies, Web Beacons y/o Tecnología Similar<br/>
          </p>
          <h5>11. Cambios al Aviso de Privacidad
          </h5>
          <p>
            Ibancar Latam S.A. de C.V. podrá realizar cambios en cualquier momento al presente Aviso de Privacidad. La versión vigente del Aviso de Privacidad estará permanentemente disponible en nuestro Sitio de Internet: www.ibancar.mx<br/>
            En caso de cambios sustanciales en este Aviso de Privacidad, te informaremos a través de tu estado de cuenta o el correo electrónico que tengas registrado con nosotros.<br/>
            Te recomendamos visitar periódicamente nuestra página para estar actualizado sobre posibles cambios al presente Aviso de Privacidad.<br/>
          </p>
        </div>
        <!-- /row1 -->
      </div>
    </div>
    <!-- /container -->
  </section>
  <!-- /section -->
</template>

<script>
import { useMeta } from 'vue-meta'

export default {
  name: "Privacidad",
  setup() {

    useMeta({
      title: 'Privacidad',
      link: [
        { href: 'https://ibancar.com/politica-privacidad', rel: 'alternate', hreflang: 'es-es' },
        { href: 'https://ibancar.mx/politica-privacidad', rel: 'alternate', hreflang: 'es-MX' }
      ]
    })
  }
};
</script>

<style scoped lang="scss">
.jumbotron {
  padding: 25px 0 0 25px;
  margin-top: 90px;
  background-color: #fff;
}

// Resposive para pantallas a partir de tamaño medio a pequeño

@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

@include media-breakpoint-between(xs, sm) {
}

@include media-breakpoint-between(md, lg) {
}

@include media-breakpoint-between(xl, xxl) {
}
</style>